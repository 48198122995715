import { type Ref, computed, ref } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useDangerouslyComputedBreakpoint } from '@backmarket/utils/composables/useDangerouslyComputedBreakpoint'
import { Breakpoint } from '@backmarket/utils/dom/getCurrentBreakpoint'

import type {
  Facet,
  UiPriceFacet,
} from '../../search/composables/useProductsSearch'

export function useTopFilters({
  priceFacet,
  facets,
  price,
  filters,
  sortOptions,
  sort,
}: {
  price: Ref<[number, number]>
  filters: Ref<Record<string, string[]>>
  facets: Ref<Facet[]>
  priceFacet?: UiPriceFacet
  sortOptions: {
    label: string
    value: string
  }[]
  sort: Ref<string>
}) {
  const i18n = useI18n()
  const sortedFilters = ref<string[]>([])
  const smScrollContainer = ref()
  const lgScrollContainer = ref()
  const breakpoint = useDangerouslyComputedBreakpoint()

  const allFilters = computed<{
    [key: string]: { value: string[]; facet: Facet | UiPriceFacet }
  }>(() => ({
    ...(priceFacet
      ? {
          price: {
            facet: priceFacet,
            value:
              price.value[0] > 0 || price.value[1] < priceFacet.max
                ? [
                    price.value
                      .map(
                        (priceValue: number) =>
                          i18n.price(priceValue, {
                            maximumFractionDigits: 0,
                          }) || 0,
                      )
                      .join(' - '),
                  ]
                : [],
          },
        }
      : {}),
    ...facets.value.reduce(
      (a, facet) => ({
        ...a,
        [facet.name]: {
          facet,
          value: filters.value[facet.name],
        },
      }),
      {},
    ),
  }))

  const scrollToLeft = () => {
    const scrollElement =
      breakpoint.value < Breakpoint.LG
        ? smScrollContainer.value
        : lgScrollContainer.value
    scrollElement?.scrollTo({
      left: 0,
      behavior: 'smooth',
    })
  }

  function applyFiltersSort() {
    sortedFilters.value = Object.values(allFilters.value)
      .filter((f, i) => i < 5 || f.value?.length)
      .sort((a, b) => {
        if (!!a.value?.length === !!b.value?.length) return 0

        return a.value?.length ? -1 : 1
      })
      .map((filter) => filter.facet.name)
    scrollToLeft()
  }

  const sortValue = computed(() =>
    sortOptions.find((i) => i.value === sort.value),
  )

  return {
    allFilters,
    sortedFilters,
    applyFiltersSort,
    smScrollContainer,
    lgScrollContainer,
    sortValue,
  }
}
